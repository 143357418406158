<template>

    <v-col 
        cols="12" sm="12" :md="colWidth"
        class="cfl-item-wrap"
    >
        <div 
            class="cfl-item"
            :class="{'active-cfl': IsActive}"
            @click="onAttachmnentClick()"
        >
            <div 
                v-if="Progress"
                class="cfl-item-progress"
                :style="{ width: Progress }"
            >
            </div>
            <div class="file-icon">
                <!--Для добавления стикера сертификата на иконку добавляем у тега <i> к существующим классам класс with-cert и еще второй класс для управления цветом success-cert или danger-cert -->
                <i class="fas fa-file-alt dark-gray-color"></i>
            </div>
            <div class="cfl-info">
                <div class="cfl-abs" v-tooltip.left-center="name">
                    <div class="cfl-filename">{{ name }}</div>
                    <div>
                        <div class="cfl-fileinfo">
                            <div v-if="Message" class="cfl-err-msg">{{Message}}</div>
                            <span v-else-if="Progress">{{Progress}}</span>
                            <span v-else>{{parseInt(size) ? `${(parseFloat(size) / (1024 * 1024)).toFixed(2)} ${$t('МБ')}` : '-'}}</span>                                                
                        </div>
                        <!-- <div class="cfl-status"></div> -->
                    </div>
                </div>
            </div>
            <div class="cfl-actions"
                v-if="!Progress"
            >
                <span 
                    v-if="!Message && !hideLoad"
                    class="download-file" 
                    v-tooltip.left-center="$t('Загрузить')"
                    @click="loadAttchment()"
                >
                    <i class="fas fa-download dark-gray-color"></i>
                </span>
                <span 
                    v-if="!isReadOnly" 
                    class="delete-file" 
                    v-tooltip.left-center="$t('Удалить')"
                    @click="deleteAttachment()"
                >
                    <i class="fas fa-trash dark-gray-color"></i>
                </span>
            </div>
        </div>
    </v-col>

</template>

<script>

export default {
    name: "FileItem",
    data() {
        return {
            
        }
    },
    props: {
        colWidth: {
            type: String,
            default: "3"
        },
        name: {
            type: String,
            default: null
        },
        IsActive: {
            type: Boolean,
            default: false
        },
        Progress: {
            type: String,
            default: null
        },
        Message: {
            type: String,
            default: null
        },
        size: {
            type: Number,
            default: 0
        },
        isReadOnly: {
            type: Boolean,
            default: false
        },
        hideLoad: {
            type: Boolean,
            default: false
        }
    },
    computed: {
       
    },
    methods: {
        onAttachmnentClick()
        {
            this.$emit('on-click-attachment');
        },
        loadAttchment()
        {
            this.$emit('on-load-attachment');
        },
        deleteAttachment()
        {
            this.$emit('on-delete-attachment');
        }
    }
}
</script>